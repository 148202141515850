import React, { useState } from "react";
import { useSpring, animated } from "@react-spring/web";

function Title() {
  // Use useSpring to create animations for the h2 elements
  const h2Trail1 = useSpring({
    from: { opacity: 0, transform: "translateX(-100%)" },
    to: { opacity: 1, transform: "translateX(0)" },
    config: { tension: 200, friction: 50 }, // Adjust to change the bounce effect
    delay: 200,
  });

  const h2Trail2 = useSpring({
    from: { opacity: 0, transform: "translateX(100%)" },
    to: { opacity: 1, transform: "translateX(0)" },
    config: { tension: 200, friction: 50 }, // Adjust to change the bounce effect
    delay: 800,
  });

  // State to control the blinking effect
  const [isBlinking, setIsBlinking] = useState(false);
  const [blinkCount, setBlinkCount] = useState(0);

  // Animation styles for blinking effect
  const blinkingStyles = useSpring({
    color: isBlinking ? "#972020" : "white",
    loop: blinkCount < 3,
    from: { color: "white" },
    config: { duration: 500 },
    onRest: () => {
      if (blinkCount < 3) {
        setBlinkCount(blinkCount + 1);
        setIsBlinking(!isBlinking);
      }
    },
  });

  // Start blinking effect after 2000ms
  setTimeout(() => setIsBlinking(true), 2000);
  return (
    <div className="h2-container">
      <animated.h2 style={h2Trail1}>Covid vaccines:</animated.h2>
      <animated.h2 style={h2Trail2}>
        How did you get{" "}
        <animated.span style={blinkingStyles}>cheated</animated.span>?
      </animated.h2>
    </div>
  );
}

export default Title;
