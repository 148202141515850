import React, { useState, useEffect, useRef } from "react";
import html2canvas from "html2canvas";

import cross from "../../images/remove_cross.png";
import dlscreen from "../../images/download_screenshot.png";

import Filter from "./Filter";
import GraphYear from "./GraphYear";
import GraphMonth from "./GraphMonth";
import OfficialGraphMonth from "./OfficialGraphMonth";

const Data = ({ closeModals }) => {
  const [graphData, setGraphData] = useState([]);

  const [isGraphYear, setIsGraphYear] = useState(false);
  const [showGraph, setShowGraph] = useState(false);

  const [selectedAge, setSelectedAge] = useState("all_ages");
  const [selectedVaccine, setSelectedVaccine] = useState("all_vaccines");
  const [selectedYear, setSelectedYear] = useState("all_years");

  let dataInfo =
    selectedVaccine === "all_vaccines" ? "ALL VACCINES" : selectedVaccine;
  dataInfo += " - ";
  dataInfo += selectedYear === "all_years" ? "ALL YEARS" : selectedYear;
  //dataInfo += " - ";
  //dataInfo += selectedAge === "all_ages" ? "" : "<18 years old";

  const currentURL = window.location.href;

  useEffect(() => {
    setIsGraphYear(graphData.length > 0 && graphData[0].official_deaths);
  }, [graphData]);

  useEffect(() => {
    if (graphData.length > 0) {
      setShowGraph(true);
    }
  }, [graphData]);

  const dataRef = useRef(null);

  const captureAndDownload = () => {
    html2canvas(dataRef.current).then((canvas) => {
      const screenshot = canvas.toDataURL("image/png");

      const link = document.createElement("a");
      link.href = screenshot;
      link.download = `${selectedVaccine}_${selectedYear}_${selectedAge}.png`;
      link.click();
    });
  };

  return (
    <div className="modal" ref={dataRef}>
      <p className="modal-info">{dataInfo}</p>
      <p className="modal-url">{currentURL}</p>
      <h2 className="modal-title">Vaccines deaths</h2>
      <img
        src={cross}
        alt="close cross"
        className="close-btn"
        onClick={closeModals}
      ></img>
      <img
        src={dlscreen}
        alt="download screenshot icon"
        className="dl-btn"
        onClick={captureAndDownload}
      ></img>
      <div className="modal-content">
        <div className="modal-filter-content">
          <Filter
            onChangeData={(
              data,
              selectedYear,
              selectedAge,
              selectedVaccine
            ) => {
              setGraphData(data);
              setSelectedAge(selectedAge);
              setSelectedVaccine(selectedVaccine);
              setSelectedYear(selectedYear);
            }}
          />
        </div>
        <div className="modal-graph-content">
          {showGraph &&
            (isGraphYear ? (
              <GraphYear graphData={graphData} />
            ) : (
              <>
                <div className="modal-graph-content-top">
                  <OfficialGraphMonth
                    graphData={graphData}
                    selectedAge={selectedAge}
                    selectedVaccine={selectedVaccine}
                    selectedYear={selectedYear}
                  />
                </div>
                <div className="modal-graph-content-bottom">
                  <GraphMonth graphData={graphData} />
                </div>
              </>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Data;
