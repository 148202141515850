export const Quizz = [
  {
    question: "Guess how many covid vaccines deaths in 2021 !",
    eudravigilance: 12996,
    eudrasurveillance: 31528,
  },
  {
    question: "Guess how many covid vaccines deaths in 2022 !",
    eudravigilance: 5786,
    eudrasurveillance: 17120,
  },
  {
    question: "Guess how many covid Pfizer vaccine deaths in 2021 !",
    eudravigilance: 3231,
    eudrasurveillance: 18901,
  },
  {
    question: "Guess how many covid Pfizer vaccine deaths in 2022 !",
    eudravigilance: 1487,
    eudrasurveillance: 10739,
  },
];
