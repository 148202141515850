import React from "react";

import cross from "../../images/remove_cross.png";

const About = ({ closeModals }) => {
  return (
    <div className="modal">
      <h2 className="modal-title-center">About</h2>
      <img
        src={cross}
        alt="close cross"
        className="close-btn"
        onClick={closeModals}
      ></img>
      <div className="modal-content-nogrid">
        <section>
          <h3>Presentation</h3>
          <p>
            This website focuses on the evolution of the Eudravigilance database
            for covid vaccines. Eudravigilance is a system established by the
            European Medicines Agency (EMA) for the monitoring and reporting of
            adverse reactions to medicines. This website provides valuable
            insights into the management and analysis of the Eudravigilance
            database. One of the key features of this website is its robust data
            backup and storage capabilities. The site ensures that all data
            within the Eudravigilance database is securely saved twice a month
            and easily accessible.
          </p>
          <p>
            The website also offers comprehensive data processing
            functionalities for the raw data collected by the EMA. This site
            employs advanced data processing techniques to analyze and extract
            meaningful insights from this raw data. Furthermore, this platform
            provides an intuitive and user-friendly interface for presenting the
            results of the data analysis. The Eudravigilance database is
            constantly evolving, with new data being added regularly. But there
            is also many data deleted.
          </p>
          <p>
            The website enables users to explore the analyzed results through
            various visualizations, such as charts, graphs, and interactive
            dashboards. These visual representations help users to gain a better
            understanding of the safety profile of medicines and make informed
            decisions. In conclusion, this website serves as an indispensable
            resource for tracking and monitoring the Eudravigilance database for
            covid vaccines. By providing timely and insightful information, this
            site contributes to the ongoing efforts to enhance pharmacovigilance
            and improve patient safety.
          </p>
          <h3>Data Processing</h3>
          <p>
            The source data for the Eudravigilance database is obtained from
            the&nbsp;
            <a
              href="https://www.adrreports.eu/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Eudravigilance website
            </a>
            . A comprehensive analysis is conducted bi-monthly using the
            downloaded database from the website to track the occurrence of
            adverse effects. The analysis focuses on the evolution of reported
            deaths caused by adverse reactions by calculating the number of
            deaths removed and the number of deaths added between two reports.
          </p>
          <p>
            Over the course of a year, the cumulative sum of added and removed
            deaths between reports forms the Eudrasurveillance data. This data
            provides valuable insights into the safety monitoring of covid
            vaccines and contributes in understanding the overall impact of
            adverse reactions over time.
          </p>
          <h3>Questions</h3>
          <p>
            The observation of differences between Eudravigilance data and
            Eudrasurveillance data raises several questions that the health
            authorities of the EMA should address.
          </p>
          <p>Why the EMA removes deaths from its vaccine database ?</p>
          <p>
            Why the removal is heterogeneous across vaccines. In other words,
            why does the EMA remove more deaths from Pfizer than from Moderna?
          </p>
        </section>
      </div>
    </div>
  );
};

export default About;
