import React, { useState, useEffect, useRef } from "react";

import cross from "../../images/remove_cross.png";
import replay from "../../images/replay.png";

import { Quizz } from "./Quizz";

import Hints from "./Hints";
import Check from "./Check";
import WinRight from "./WinRight";

const Game = ({ closeModals }) => {
  const inputRef = useRef(null);
  const answerRef = useRef(null);
  const instructionRef = useRef(null);
  const checkRef = useRef(null);

  const [backgroundColor, setBackgroundColor] = useState("#550000");

  const [guessNb, setGuessNb] = useState("");
  const [answer, setAnswer] = useState(Quizz[0].eudravigilance);
  const [userMessage, setUserMessage] = useState("Start guessing...");

  const [isWin, setIsWin] = useState(false);

  const [questionNb, setQuestionNb] = useState(0);

  const handleCheck = () => {
    if (guessNb === answer) {
      setUserMessage("Yes you fount it! Play again !");
      setBackgroundColor("#032b1b");
      inputRef.current.disabled = true;
      answerRef.current.innerHTML = answer;
      instructionRef.current.innerHTML = "Eudravigilance deaths count:";
      setIsWin(true);
      checkRef.current.style.display = "none";
    } else {
      const message = guessNb > answer ? "📈 Too High!" : "📉 Too Low!";
      setUserMessage(message);
      inputRef.current.focus();
    }
  };

  const gameReplay = () => {
    setGuessNb(0);
    updateQuestionNb();
    setUserMessage("Start guessing...");
    setBackgroundColor("#550000");
    inputRef.current.disabled = false;
    answerRef.current.innerHTML = "?";
    instructionRef.current.innerHTML = "Try a number !";
    setIsWin(false);
    checkRef.current.style.display = "block";
    inputRef.current.focus();
  };

  const updateQuestionNb = () => {
    if (questionNb < Quizz.length - 1) {
      setQuestionNb(questionNb + 1);
    } else {
      setQuestionNb(0);
    }
  };

  useEffect(() => {
    setAnswer(Quizz[questionNb].eudravigilance);
  }, [questionNb]);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <div className="modal" style={{ backgroundColor }}>
      <div className="modal-game-actions">
        <img
          src={cross}
          alt="close cross"
          className="close-btn"
          onClick={closeModals}
        ></img>
        <img
          src={replay}
          alt="play again"
          className="dl-btn"
          onClick={gameReplay}
        ></img>
      </div>
      <div className="modal-game-top">
        <h1>{Quizz[questionNb].question}</h1>
        <div className="game-count" ref={answerRef}>
          ?
        </div>
      </div>
      <div className="modal-game-message">
        <p className="game-message">{userMessage}</p>
      </div>
      <div className="modal-game-main">
        <div className="modal-game-left">
          <Check
            instructionRef={instructionRef}
            inputRef={inputRef}
            guessNb={guessNb}
            setGuessNb={setGuessNb}
            handleCheck={handleCheck}
            checkRef={checkRef}
          />
        </div>
        <div className="modal-game-right">
          {isWin ? (
            <WinRight eudrasurveillance={Quizz[questionNb].eudrasurveillance} />
          ) : (
            <Hints />
          )}
        </div>
      </div>
    </div>
  );
};

export default Game;
