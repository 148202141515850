import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";

function Reports() {
  const host =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_PROXY_HOST_PROD
      : process.env.REACT_APP_PROXY_HOST_DEV;

  const [selectedDate, setSelectedDate] = useState("Aug 1st");
  const [selectedVaccine, setSelectedVaccine] = useState(
    "PFIZER-BIONTECH_TOZINAMERAN"
  );
  const [selectedYear, setSelectedYear] = useState("2021");

  const [vaccinesList, setVaccinesList] = useState([]);
  const [yearsList, setYearsList] = useState([]);
  const [datesList, setDatesList] = useState([]);

  const updateVaccinesList = useCallback(async () => {
    try {
      const response = await axios.post(host + "/api/getVaccines", {
        age: "all_ages",
        year: selectedYear,
      });
      const vaxFullnames = response.data.map((vaccine) => vaccine.fullname);
      setVaccinesList(vaxFullnames);
    } catch (error) {
      console.error("Error on API fetch from /api/getVaccines:", error);
    }
  }, [selectedYear, host]);

  const updateYearsList = useCallback(async () => {
    try {
      const response = await axios.get(host + "/api/getYears", {
        age: "all_ages",
        vaccine: selectedVaccine,
      });
      setYearsList(response.data);
    } catch (error) {
      console.error("Error on API fetch from /api/getYears:", error);
    }
  }, [selectedVaccine, host]);

  const updateDatesList = useCallback(async () => {
    try {
      const response = await axios.post(host + "/api/getDates", {
        age: "all_ages",
        vaccine: selectedVaccine,
        year: selectedYear,
      });
      setDatesList(response.data);
    } catch (error) {
      console.error("Error on API fetch from /api/getDates:", error);
    }
  }, [selectedVaccine, selectedYear, host]);

  const getReport = async () => {
    const data = {
      year: selectedYear,
      vaccine: selectedVaccine,
      date: selectedDate,
    };

    axios
      .post(host + "/api/downloadReport", data, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement("a");
        link.href = url;
        const trimmedDate = selectedDate.replace(/\s/g, "");
        link.setAttribute(
          "download",
          `${selectedVaccine}_${selectedYear}_${trimmedDate}_eudravigilance_report.csv`
        );

        document.body.appendChild(link);

        link.click();

        window.URL.revokeObjectURL(url);

        link.remove();
      })
      .catch((error) => {
        console.error("An error occurred while downloading the files :", error);
      });
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleVaccineChange = (event) => {
    setSelectedVaccine(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    // update selectedVaccine state after the DOM is updated
    // cause selectedVaccine state is not contained in the new vaccinesList
    setTimeout(() => {
      const vaccineElement = document.getElementById("vaccine");
      setSelectedVaccine(vaccineElement.value);
      const dateElement = document.getElementById("date");
      setSelectedDate(dateElement.value);
    }, 100);
  };

  useEffect(() => {
    updateYearsList();
  }, [updateYearsList]);

  useEffect(() => {
    updateVaccinesList();
  }, [updateVaccinesList, selectedYear]);

  useEffect(() => {
    updateDatesList();
  }, [updateDatesList, selectedYear, selectedVaccine]);

  return (
    <div>
      <div>
        <select
          id="year"
          value={selectedYear}
          onChange={handleYearChange}
          className="select"
        >
          {yearsList.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>

      <div>
        <select
          id="vaccine"
          value={selectedVaccine}
          onChange={handleVaccineChange}
          className="select"
        >
          {vaccinesList.map((vaccine) => (
            <option key={vaccine} value={vaccine}>
              {vaccine}
            </option>
          ))}
        </select>
      </div>

      <div>
        <select
          id="date"
          value={selectedDate}
          onChange={handleDateChange}
          className="select"
        >
          {datesList.map((date) => (
            <option key={date} value={date}>
              {date}
            </option>
          ))}
        </select>
      </div>
      <div>
        {
          <button className="modal-btn" onClick={getReport}>
            Download Report
          </button>
        }
      </div>
    </div>
  );
}

export default Reports;
