import React, { useState, useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";

import virus_white from "../../images/virus_white.png";
import virus_red from "../../images/virus_red.png";

function Virus({ handleNavClick }) {
  // Use useSpring to create animations for the virus elements
  const virusTrail1Translation = useSpring({
    from: { opacity: 0, transform: "translate(-100%, -1000%)" },
    to: { opacity: 1, transform: "translate(0%, 0%)" },
    config: { tension: 180, friction: 12 }, // Adjust to change the bounce effect
    delay: 2000,
  });

  const virusTrail1Rotation = useSpring({
    from: { rotate: 0 },
    to: { rotate: -360 },
    config: { duration: 50000 },
    loop: true,
  });

  const virusTrail2Translation = useSpring({
    from: { opacity: 0, transform: "translate(-100%, 1000%)" },
    to: { opacity: 1, transform: "translate(0%, 0%)" },
    config: { tension: 180, friction: 12 }, // Adjust to change the bounce effect
    delay: 2500,
  });

  const virusTrail2Rotation = useSpring({
    from: { rotate: 0 },
    to: { rotate: 360 },
    config: { duration: 50000 },
    loop: true,
  });

  // Add virus-text after 5.5s
  const [isHidden, setHidden] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setHidden(false);
    }, 3500);

    return () => clearTimeout(timeout);
  }, []);

  const fadeProps = useSpring({
    opacity: isHidden ? 0 : 1,
    display: isHidden ? "none" : "block",
  });

  return (
    <div className="virus-container">
      <animated.div className={"virus1-text"} style={fadeProps}>
        data
      </animated.div>
      <animated.div className={"virus2-text"} style={fadeProps}>
        game
      </animated.div>
      <animated.div style={virusTrail1Translation}>
        <animated.div style={virusTrail1Rotation}>
          <div className="virus1">
            <img
              src={virus_red}
              alt="first virus"
              onClick={() => handleNavClick("Data")}
            />
          </div>
        </animated.div>
      </animated.div>
      <animated.div style={virusTrail2Translation}>
        <animated.div style={virusTrail2Rotation}>
          <div className="virus2">
            <img
              src={virus_white}
              alt="second virus"
              onClick={() => handleNavClick("Game")}
            />
          </div>
        </animated.div>
      </animated.div>
    </div>
  );
}

export default Virus;
