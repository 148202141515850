import React, { useState } from "react";

import NavBar from "./NavBar";
import Title from "./Title";
import Virus from "./Virus";
import Data from "../Data";
import Game from "../Game";
import Contact from "../Contact";
import About from "../About";
import Archives from "../Archives";

const Landing = () => {
  const [showData, setShowData] = useState(false);
  const [showGame, setShowGame] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  const [showArchives, setShowArchives] = useState(false);

  const closeModals = () => {
    setShowData(false);
    setShowGame(false);
    setShowContact(false);
    setShowAbout(false);
    setShowArchives(false);
  };

  const handleNavClick = (navItem) => {
    closeModals();
    if (navItem === "Archives") {
      setShowArchives(true);
    } else if (navItem === "About") {
      setShowAbout(true);
    } else if (navItem === "Contact") {
      setShowContact(true);
    } else if (navItem === "Data") {
      setShowData(true);
    } else if (navItem === "Game") {
      setShowGame(true);
    }
  };

  return (
    <section>
      <NavBar handleNavClick={handleNavClick} />
      {showData && <Data closeModals={closeModals} />}
      {showGame && <Game closeModals={closeModals} />}
      {showContact && <Contact closeModals={closeModals} />}
      {showAbout && <About closeModals={closeModals} />}
      {showArchives && <Archives closeModals={closeModals} />}
      <Title />
      <Virus handleNavClick={handleNavClick} />
    </section>
  );
};

export default Landing;
