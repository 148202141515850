import React from "react";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import { useTrail, animated } from "@react-spring/web";

function NavBar({ handleNavClick }) {
  // Set the starting styles for navigation bar items
  const initialStyles = { opacity: 0, transform: "translateX(100%)" };

  // List of items in the navigation bar
  const navItems = ["Archives", "About", "Contact"];

  // Use useTrail to create individual animations for each nav-item
  const trail = useTrail(navItems.length, {
    from: initialStyles,
    to: { opacity: 1, transform: "translateX(0)" },
    config: { tension: 200, friction: 20 }, // Adjust to change the bounce effect
    delay: 100,
  });

  // compute Nav className
  const getNavClass = (navItem) => {
    if (navItem === "Archives") {
      return "nav-item archives";
    } else if (navItem === "About") {
      return "nav-item about";
    } else if (navItem === "Contact") {
      return "nav-item contact";
    } else {
      return "nav-item";
    }
  };

  return (
    <nav>
      {trail.map((styles, index) => (
        <animated.div
          key={index}
          style={styles}
          className={getNavClass(navItems[index])}
          data-tooltip-id={navItems[index]}
          data-tooltip-content={navItems[index]}
          data-tooltip-place="bottom-end"
          onClick={() => handleNavClick(navItems[index])}
        >
          <Tooltip id={navItems[index]} />
        </animated.div>
      ))}
    </nav>
  );
}

export default NavBar;
