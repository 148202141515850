import React from "react";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <section>
      <div className="h2-container">
        <h2>Wrong url !</h2>
        <h2>
          <u onClick={() => navigate("/")}>Click</u> to go to the index page !
        </h2>
      </div>
    </section>
  );
};

export default ErrorPage;
