import React from "react";

import logo from "../../images/logo.png";

const Header = () => {
  return (
    <header>
      <div className="title">
        <h1>
          <img src={logo} alt="logo eudrasurveillance" width={50} />
          <span style={{ color: "#972020" }}>Eudra</span>surveillance
          <hr />
        </h1>
      </div>
    </header>
  );
};

export default Header;
