import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";

function Hints() {
  const host =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_PROXY_HOST_PROD
      : process.env.REACT_APP_PROXY_HOST_DEV;

  const [hintNb, setHintNb] = useState(0);
  const [hints, setHints] = useState([
    { vaccineGroup: "", vaccinesList: [], year: "", deaths: "", deaths18: "" },
  ]);

  const initHints = useCallback(async () => {
    try {
      const response = await axios.get(host + "/api/getHints");
      setHints(response.data);
    } catch (error) {
      console.error("Error on API fetch from /api/getHints:", error);
    }
  }, [host]);

  const updateHintNb = () => {
    if (hintNb < hints.length - 1) {
      setHintNb(hintNb + 1);
    } else {
      setHintNb(0);
    }
  };

  useEffect(() => {
    initHints();
  }, [initHints]);

  return (
    <div className="game-hint">
      <p className="game-hint-title">HINTS :</p>
      <p>
        <span>Vaccines group</span>: {hints[hintNb].vaccineGroup}
      </p>
      <p>
        <span>Year</span>: {hints[hintNb].year}
      </p>
      <p>
        <span>Vaccines count</span>: {hints[hintNb].vaccinesList.length}
      </p>
      <p>
        <span>Deaths count</span>: {hints[hintNb].deaths}
      </p>
      <p>
        <span>Deaths under 18 years old</span>: {hints[hintNb].deaths18}
      </p>
      <div className="new-hint-btn">
        <button className="modal-btn" onClick={updateHintNb}>
          New hint
        </button>
      </div>
    </div>
  );
}

export default Hints;
