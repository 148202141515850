import React from "react";

import cross from "../../images/remove_cross.png";

import Results from "./Results";
import Reports from "./Reports";

const Archives = ({ closeModals }) => {
  return (
    <div className="modal">
      <h2 className="modal-title-center">Archives</h2>
      <img
        src={cross}
        alt="close cross"
        className="close-btn"
        onClick={closeModals}
      ></img>
      <div className="modal-content-archives">
        <div className="modal-reports-content">
          <div className="archives-border">
            <h3>Select an Eudravigilance report</h3>
            <hr />
            <Reports />
          </div>
        </div>
        <div className="modal-results-content">
          <div className="archives-border">
            <h3>Select an Eudrasurveillance result</h3>
            <hr />
            <Results />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Archives;
