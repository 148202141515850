//React components
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//CSS
import "../../App.css";
import corona from "../../images/corona.gif";

//Application components
import Header from "../Header";
import Landing from "../Landing";
import Footer from "../Footer";
import ErrorPage from "../ErrorPage";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const backgroundImage = new Image();
    backgroundImage.src = corona;

    let startTime = 0;

    const handleImageLoad = () => {
      const endTime = performance.now();
      const elapsedTime = endTime - startTime;
      const delay = Math.max(3000 - elapsedTime, 0);

      setTimeout(() => {
        setLoading(false);
      }, delay);
    };

    backgroundImage.onload = () => {
      startTime = performance.now();
      handleImageLoad();
    };
  }, []);

  return (
    <div className={`${loading ? "loading" : ""}`}>
      <Router>
        {loading ? null : (
          <>
            <Header />

            <Routes>
              <Route exact path="/" element={<Landing />} />
              <Route path="*" element={<ErrorPage />} />
            </Routes>

            <Footer />
          </>
        )}
      </Router>
    </div>
  );
}

export default App;
