import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";

const Filter = ({ onChangeData }) => {
  const host =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_PROXY_HOST_PROD
      : process.env.REACT_APP_PROXY_HOST_DEV;

  const [selectedAge, setSelectedAge] = useState("all_ages");
  const [selectedVaccine, setSelectedVaccine] = useState("all_vaccines");
  const [selectedYear, setSelectedYear] = useState("all_years");

  const [vaccinesList, setVaccinesList] = useState([]);
  const [yearsList, setYearsList] = useState([]);

  const [showBtn, setShowBtn] = useState(true);

  const updateVaccinesList = useCallback(async () => {
    try {
      const response = await axios.post(host + "/api/getVaccines", {
        age: selectedAge,
        year: selectedYear,
      });
      const vaxFullnames = response.data.map((vaccine) => vaccine.fullname);
      setVaccinesList(vaxFullnames);
    } catch (error) {
      console.error("Error on API fetch from /api/getVaccines:", error);
    }
  }, [selectedAge, selectedYear, host]);

  const updateYearsList = useCallback(async () => {
    try {
      const response = await axios.get(host + "/api/getYears", {
        age: selectedAge,
        vaccine: selectedVaccine,
      });
      setYearsList(response.data);
    } catch (error) {
      console.error("Error on API fetch from /api/getYears:", error);
    }
  }, [selectedAge, selectedVaccine, host]);

  const getData = useCallback(async () => {
    try {
      const response = await axios.post(host + "/api/data", {
        age: selectedAge,
        year: selectedYear,
        vaccine: selectedVaccine,
      });
      onChangeData(response.data, selectedYear, selectedAge, selectedVaccine);
    } catch (error) {
      console.error("Error on API fetch from /api/data:", error);
    }
  }, [host, selectedAge, selectedYear, selectedVaccine, onChangeData]);

  useEffect(() => {
    updateVaccinesList();
    updateYearsList();
  }, [updateVaccinesList, updateYearsList]);

  const prevSelectedAgeRef = useRef(selectedAge);
  const prevSelectedVaccineRef = useRef(selectedVaccine);
  const prevSelectedYearRef = useRef(selectedYear);

  useEffect(() => {
    const fetchData = async () => {
      if (
        prevSelectedAgeRef.current !== selectedAge ||
        prevSelectedYearRef.current !== selectedYear ||
        prevSelectedVaccineRef.current !== selectedVaccine
      ) {
        await getData();
      }

      prevSelectedAgeRef.current = selectedAge;
      prevSelectedVaccineRef.current = selectedVaccine;
      prevSelectedYearRef.current = selectedYear;
    };

    fetchData();
  }, [getData, selectedAge, selectedVaccine, selectedYear]);

  const handleAgeChange = (event) => {
    setSelectedAge(event.target.value);
    setShowBtn(false);
  };

  const handleVaccineChange = (event) => {
    setSelectedVaccine(event.target.value);
    setShowBtn(false);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    setSelectedVaccine("all_vaccines");
    // update selectedVaccine state after the DOM is updated
    // cause selectedVaccine state is not contained in the new vaccinesList
    setTimeout(() => {
      const vaccineElement = document.getElementById("vaccine");
      setSelectedVaccine(vaccineElement.value);
    }, 100);
    setShowBtn(false);
  };

  const handleShowBtn = () => {
    getData();
    setShowBtn(!showBtn);
  };

  return (
    <div>
      <div>
        <label htmlFor="year">Years :</label>
        <br />
        <select
          id="year"
          value={selectedYear}
          onChange={handleYearChange}
          className="select"
        >
          <option value="all_years">All</option>
          {yearsList.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label htmlFor="vaccine">Vaccines :</label>
        <br />
        <select
          id="vaccine"
          value={selectedVaccine}
          onChange={handleVaccineChange}
          className="select"
        >
          <option value="all_vaccines">All</option>
          {vaccinesList.map((vaccine) => (
            <option key={vaccine} value={vaccine}>
              {vaccine}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label htmlFor="age">Ages :</label>
        <br />
        <select
          id="age"
          value={selectedAge}
          onChange={handleAgeChange}
          className="select"
        >
          <option value="all_ages">All</option>
          <option value="less_18">{`<18 years old`}</option>
        </select>
      </div>

      <div>
        {showBtn && (
          <button className="modal-btn" onClick={handleShowBtn}>
            Show Data
          </button>
        )}
      </div>
    </div>
  );
};

export default Filter;
