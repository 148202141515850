import React from "react";

function Check({
  instructionRef,
  inputRef,
  guessNb,
  setGuessNb,
  handleCheck,
  checkRef,
}) {
  return (
    <div className="game-check">
      <p className="game-instruction" ref={instructionRef}>
        Try a number !
      </p>
      <input
        type="number"
        className="game-guess"
        ref={inputRef}
        value={guessNb}
        onChange={(e) => setGuessNb(Number(e.target.value))}
      />
      <button className="modal-btn" ref={checkRef} onClick={handleCheck}>
        Check!
      </button>
    </div>
  );
}

export default Check;
