import React, { useRef, useState, useEffect, useCallback } from "react";
import Plot from "react-plotly.js";

function GraphMonth(props) {
  const dataArray = props.graphData;
  const xaxisTitle = "Vaccine Deaths";
  const plotContainer = useRef(null);

  const [x1, setX1] = useState([]);
  const [x2, setX2] = useState([]);
  const [x3, setX3] = useState([]);
  const [y, setY] = useState([]);

  const buildPlot = useCallback(() => {
    const x1 = [dataArray[0].nb_death_vaccine_before]; // cumulative deaths
    const x2 = [0]; // deleted deaths
    const x3 = [0]; // added deaths
    const y = [dataArray[0].date_before];
    dataArray.map((obj, index) => {
      x1.push(x1[index] + x2[index] + x3[index]);
      x2.push(obj.nb_vaccine_deleted);
      x3.push(obj.nb_vaccine_added);
      y.push(obj.date_after);
      return null;
    });
    setX1(x1);
    setX2(x2);
    setX3(x3);
    setY(y);
  }, [dataArray]);

  useEffect(() => {
    buildPlot();
  }, [buildPlot]);

  useEffect(() => {
    function updateSize() {
      const { current } = plotContainer;

      if (current) {
        Plot.update(current, {
          width: current.offsetWidth,
          //height: current.offsetHeight,
        });
      }
    }

    window.addEventListener("resize", updateSize);
    updateSize();

    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  const data = [
    {
      x: x1,
      y: y,
      type: "bar",
      orientation: "h",
      name: "Eudrasurveillance cumulative deaths",
      marker: {
        color: "#801515",
      },
    },
    {
      x: x2,
      y: y,
      type: "bar",
      orientation: "h",
      name: "deaths removed from the previous report",
      marker: {
        color: "#D46A6A",
      },
    },
    {
      x: x3,
      y: y,
      type: "bar",
      orientation: "h",
      name: "deaths added from the previous report",
      marker: {
        color: "#FFAAAA",
      },
    },
  ];

  const layout = {
    barmode: "stack",
    height: 380,
    margin: {
      t: 10,
      r: 10,
      l: 10,
      b: 40,
    },
    legend: {
      x: 0.95,
      y: 1.25,
      xanchor: "right",
      yanchor: "top",
    },
    font: {
      family: "Times New Roman",
      size: 12,
      color: "#ffaaaa",
    },
    xaxis: {
      title: xaxisTitle,
      linecolor: "#ffaaaa",
      gridcolor: "rgba(255, 255, 255, 0.2)",
      zerolinecolor: "#ffaaaa",
      fixedrange: true, //unactive horizontal zoom
    },
    yaxis: {
      linecolor: "#ffaaaa",
      zerolinecolor: "#ffaaaa",
      gridcolor: "rgba(255, 255, 255, 0.2)",
      automargin: true, // margin auto to adapt y values lenght
      fixedrange: true, // unactive vertical zoom
    },
    paper_bgcolor: "rgba(0,0,0,0)",
    plot_bgcolor: "rgba(0,0,0,0)",
    hovermode: false, // unactive hover for the graph
    dragmode: false, // unactive dragging for the graph
  };

  return (
    <Plot
      data={data}
      layout={layout}
      config={{
        responsive: true,
        displayModeBar: false,
      }}
      style={{
        width: "100%",
        height: "100%",
      }}
    />
  );
}

export default GraphMonth;
