import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

import cross from "../../images/remove_cross.png";

const Contact = ({ closeModals }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertColor, setAlertColor] = useState("#ffaaaa");

  const host =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_PROXY_HOST_PROD
      : process.env.REACT_APP_PROXY_HOST_DEV;

  const handleRecaptchaChange = (value) => {
    // The value is returned when the reCAPTCHA is successfully verified
    if (value) {
      setIsVerified(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isVerified) {
      // If reCAPTCHA is not verified, display an error message or take other appropriate action
      setAlertMessage("Please verify the reCAPTCHA.");
      setAlertColor("red");
      return;
    }

    // Create an object with form data
    const formData = {
      name: name,
      email: email,
      message: message,
    };

    // Submit form data to Email Send API
    axios
      .post(host + "/api/sendEmail", formData)
      .then((response) => {
        // The form has been sent successfully
        setAlertMessage("Your message has been sent!");
        setAlertColor("#ffaaaa");
        // Reset fields after form submission
        setName("");
        setEmail("");
        setMessage("");
        setIsVerified(false);
      })
      .catch((error) => {
        // An error occurred while submitting the form
        setAlertMessage(
          "An error occurred while submitting the form : " + error
        );
        setAlertColor("red");
        // Reset fields after form submission even if there is an error
        setName("");
        setEmail("");
        setMessage("");
        setIsVerified(false);
        setAlertMessage("");
        setAlertColor("#ffaaaa");
      });
  };

  return (
    <div className="modal">
      <h2 className="modal-title-center">Contact</h2>
      <img
        src={cross}
        alt="close cross"
        className="close-btn"
        onClick={closeModals}
      ></img>
      <div className="modal-content-nogrid">
        <form onSubmit={handleSubmit}>
          <div className="inputBox">
            <label htmlFor="name">Name</label>
            <br />
            <input
              onChange={(e) => setName(e.target.value)}
              value={name}
              type="text"
              autoComplete="off"
              required
            />
          </div>
          <div className="inputBox">
            <label htmlFor="email">Email</label>
            <br />
            <input
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              type="email"
              autoComplete="off"
              required
            />
          </div>
          <div className="messageBox">
            <label htmlFor="message">Message</label>
            <br />
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </div>
          <div>
            <p style={{ color: alertColor }}>{alertMessage}</p>
          </div>

          <div className="sendBtnContainer">
            <button className="modal-btn" type="submit">
              Send Message
            </button>
          </div>

          <ReCAPTCHA
            className="recaptcha"
            sitekey="6LfEKMgmAAAAAB3LkiqQ3EadhPcUbZyoc8xj0_LS"
            onChange={handleRecaptchaChange}
          />
        </form>
      </div>
    </div>
  );
};

export default Contact;
