import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";

function Results() {
  const host =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_PROXY_HOST_PROD
      : process.env.REACT_APP_PROXY_HOST_DEV;

  const [selectedAge, setSelectedAge] = useState("all_ages");
  const [selectedVaccine, setSelectedVaccine] = useState(
    "PFIZER-BIONTECH_TOZINAMERAN"
  );
  const [selectedYear, setSelectedYear] = useState("2021");

  const [vaccinesList, setVaccinesList] = useState([]);
  const [yearsList, setYearsList] = useState([]);

  const updateVaccinesList = useCallback(async () => {
    try {
      const response = await axios.post(host + "/api/getVaccines", {
        age: "all_ages",
        year: selectedYear,
      });
      const vaxFullnames = response.data.map((vaccine) => vaccine.fullname);
      setVaccinesList(vaxFullnames);
    } catch (error) {
      console.error("Error on API fetch from /api/getVaccines:", error);
    }
  }, [selectedYear, host]);

  const updateYearsList = useCallback(async () => {
    try {
      const response = await axios.get(host + "/api/getYears", {
        age: "all_ages",
        vaccine: selectedVaccine,
      });
      setYearsList(response.data);
    } catch (error) {
      console.error("Error on API fetch from /api/getYears:", error);
    }
  }, [selectedVaccine, host]);

  const getResult = async () => {
    const data = {
      year: selectedYear,
      vaccine: selectedVaccine,
      age: selectedAge,
    };

    try {
      const response = await axios.post(
        host + "/api/downloadYearResult",
        data,
        {
          responseType: "blob",
        }
      );

      const yearLogsBlob = new Blob([response.data]);
      const yearLogsUrl = window.URL.createObjectURL(yearLogsBlob);
      const yearLogsLink = document.createElement("a");
      yearLogsLink.href = yearLogsUrl;
      yearLogsLink.setAttribute(
        "download",
        `${selectedVaccine}_${selectedYear}_${selectedAge}_yearLogs.json`
      );
      document.body.appendChild(yearLogsLink);
      yearLogsLink.click();
      window.URL.revokeObjectURL(yearLogsUrl);
      yearLogsLink.remove();
    } catch (error) {
      console.error("An error occurred while downloading the files :", error);
    }

    try {
      const response = await axios.post(
        host + "/api/downloadMonthResult",
        data,
        {
          responseType: "blob",
        }
      );

      const monthLogsBlob = new Blob([response.data]);
      const monthLogsUrl = window.URL.createObjectURL(monthLogsBlob);
      const monthLogsLink = document.createElement("a");
      monthLogsLink.href = monthLogsUrl;
      monthLogsLink.setAttribute(
        "download",
        `${selectedVaccine}_${selectedYear}_${selectedAge}_monthLogs.json`
      );
      document.body.appendChild(monthLogsLink);
      monthLogsLink.click();
      window.URL.revokeObjectURL(monthLogsUrl);
      monthLogsLink.remove();
    } catch (error) {
      console.error("An error occurred while downloading the files :", error);
    }
  };

  const handleAgeChange = (event) => {
    setSelectedAge(event.target.value);
  };

  const handleVaccineChange = (event) => {
    setSelectedVaccine(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    // update selectedVaccine state after the DOM is updated
    // cause selectedVaccine state is not contained in the new vaccinesList
    setTimeout(() => {
      const vaccineElement = document.getElementById("vaccine");
      setSelectedVaccine(vaccineElement.value);
    }, 100);
  };

  useEffect(() => {
    updateYearsList();
  }, [updateYearsList]);

  useEffect(() => {
    updateVaccinesList();
  }, [updateVaccinesList, selectedYear]);

  return (
    <div>
      <div>
        <select
          id="year"
          value={selectedYear}
          onChange={handleYearChange}
          className="select"
        >
          {yearsList.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>

      <div>
        <select
          id="vaccine"
          value={selectedVaccine}
          onChange={handleVaccineChange}
          className="select"
        >
          {vaccinesList.map((vaccine) => (
            <option key={vaccine} value={vaccine}>
              {vaccine}
            </option>
          ))}
        </select>
      </div>

      <div>
        <select
          id="date"
          value={selectedAge}
          onChange={handleAgeChange}
          className="select"
        >
          <option value="all_ages">All ages</option>
          <option value="less_18">Less than 18 years old</option>
        </select>
      </div>
      <div>
        {
          <button className="modal-btn" onClick={getResult}>
            Download Result
          </button>
        }
      </div>
    </div>
  );
}

export default Results;
