import React from "react";

function WinRight({ eudrasurveillance }) {
  return (
    <div className="game-check">
      <p className="game-instruction">Eudrasurveillance deaths count:</p>
      <input
        type="number"
        className="game-guess"
        value={eudrasurveillance}
        disabled
      />
    </div>
  );
}

export default WinRight;
