import React, { useRef, useState, useEffect, useCallback } from "react";
import Plot from "react-plotly.js";

function GraphYear(props) {
  const dataArray = props.graphData;
  const titleGraph = "Vaccines Deaths";
  const plotContainer = useRef(null);

  const [x, setX] = useState([]);
  const [y, setY] = useState([]);
  const [z, setZ] = useState([]);

  const buildPlot = useCallback(() => {
    const x = [];
    const y = [];
    const z = [];
    dataArray.map((obj) => {
      x.push(obj.official_deaths);
      y.push(obj.vaccine);
      z.push(obj.total_deaths);
      return null;
    });
    setX(x);
    setY(y);
    setZ(z);
  }, [dataArray]);

  useEffect(() => {
    buildPlot();
  }, [buildPlot]);

  useEffect(() => {
    function updateSize() {
      const { current } = plotContainer;

      if (current) {
        Plot.update(current, {
          width: current.offsetWidth,
          //height: current.offsetHeight,
        });
      }
    }

    window.addEventListener("resize", updateSize);
    updateSize();

    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  const data = [
    {
      x: x,
      y: y,
      type: "bar",
      orientation: "h",
      name: "Eudravigilance",
      marker: {
        color: "#801515",
      },
    },
    {
      x: z,
      y: y,
      type: "bar",
      orientation: "h",
      name: "Eudrasurveillance",
      marker: {
        color: "#FFAAAA",
      },
    },
  ];

  const layout = {
    height: 400,
    margin: {
      t: 30,
      r: 10,
      l: 40,
      b: 100,
    },
    legend: {
      x: 1,
      y: 1.2,
      xanchor: "right",
      yanchor: "top",
    },
    font: {
      family: "Times New Roman",
      size: 12,
      color: "#ffaaaa",
    },
    xaxis: {
      title: titleGraph,
      linecolor: "#ffaaaa",
      gridcolor: "rgba(255, 255, 255, 0.2)",
      zerolinecolor: "#ffaaaa",
      fixedrange: true, //unactive horizontal zoom
    },
    yaxis: {
      linecolor: "#ffaaaa",
      zerolinecolor: "#ffaaaa",
      gridcolor: "rgba(255, 255, 255, 0.2)",
      automargin: true, // margin auto to adapt y values lenght
      fixedrange: true, // unactive vertical zoom
    },
    paper_bgcolor: "rgba(0,0,0,0)",
    plot_bgcolor: "rgba(0,0,0,0)",
    hovermode: false, // unactive hover for the graph
    dragmode: false, // unactive dragging for the graph
  };

  return (
    <Plot
      data={data}
      layout={layout}
      config={{
        responsive: true,
        displayModeBar: false,
      }}
      style={{
        width: "100%",
        height: "100%",
      }}
    />
  );
}

export default GraphYear;
